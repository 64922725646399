<template>
    <div class="infoCard">
		<div class="cornerIcon">
			<img :src="iconSrc" class="iconImg" alt="">
		</div>
		<div class="infoText">{{itemName}}</div>
		<active-number class="infoValue" :number="itemValue"></active-number>
	</div>
</template>

<style lang="less" scoped>
.infoCard {
	height: 100%;
	position: relative;
	background: linear-gradient(180deg, #433818 0%, rgba(255,255,255,0) 100%);
	box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
	opacity: 1;

	.infoText {
		margin-top: 0.56vh;
		margin-left: 8.47%;
		height: 2.04;
		width: 100%;
		line-height: 2.04vh;
		font-size: 1.3vh;
		color: rgba(255,255,255,0.6);
		font-family: 'pingfangSc', sans-serif;
		white-space: nowrap;
	}
	.infoValue {
		margin-left: 8.47%;
		line-height: 2.13vh;
		font-size: 1.85vh;
		font-family: 'titleFont', sans-serif;
		background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-color: #0af;
	}

	.cornerIcon {
		position: absolute;
		right: 0;
		bottom: 0;
		height: 75%;
		aspect-ratio: 1/1;

		.iconImg {
			width: 100%;
			height: 100%;
		}

	}
}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props: ['itemName','itemValue','imgsrc'],
	components:{
		activeNumber
	},
	data(){
		return {
			iconSrc: '',
		}
	},
	mounted(){
		let ctx = require.context('@/assets/page1',false,/.png$/);
		this.iconSrc = ctx(this.imgsrc);
		return false;
	}
}
</script>

